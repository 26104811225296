import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import SiteMap from '../components/SiteMap'
import '../css/support.css';

class support extends Component {

    render() {
        const rightColumn = {
            backgroundColor: "#F7F7FF",
          };
        const leftColumn = {
            backgroundColor: "#F7F7FF",
        };
        const title ={
            color:"#6E6EFA",
            fontWeight: "bold",
        }
        const background ={
            backgroundColor: "#F7F7FF",
        }
        const text={
            color:"black",
        }
        return (
            <div style={background}>
                <Navbar />
                <div className="display-desktop">
                <div className="titlee" style={title}>Support Us</div>
                    <div className="container">
                        <div className="leftColumn" style={leftColumn}>
                            <br></br>
                            <div style={text}>
                            Join us in our mission to improve aid workers’ performance in crisis situations. 
                            <br></br><br></br>
                            Your donation will be used to sustain our platform for years to come. We have costs in running this virtual platform, licensing and the coordination of the providers and volunteers. We are committed to helping alleviate mental health pressures of volunteers, resulting in them performing at their highest ability. 
                            <br></br><br></br>
                            Help us, help others by donating to Huddl. </div>
                    </div>
                    <div className = "rightColumn" style={rightColumn}>
                        <img className="image" src="https://img.icons8.com/material-outlined/300/000000/groups.png"/>
                    </div>
                </div>
                </div>
                <div className="display-mobile">
                    <div className = "container">
                        <div className="title" style={title}>Support Us</div>
                        <div className = "rightColumn" style={rightColumn}>
                        <img className="image" src="https://img.icons8.com/material-outlined/300/000000/groups.png"/>
                        </div>
                    </div>
                    <div className="leftColumn" style={leftColumn}>
                    <br></br>
                            <div style={text}>
                            Join us in our mission to improve aid workers’ performance in crisis situations. 
                            <br></br><br></br>
                            Your donation will be used to sustain our platform for years to come. We have costs in running this virtual platform, licensing and the coordination of the providers and volunteers. We are committed to helping alleviate mental health pressures of volunteers, resulting in them performing at their highest ability. 
                            <br></br><br></br>
                            Help us, help others by donating to Huddl. </div>
                    </div>
                    </div>
                <SiteMap />
            </div>
        )
    }
}

export default support