import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Material-UI Imports
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import logo from '../image/Huddl_Logo.png'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem';
import '../css/header.css';
import {List} from 'react-bootstrap-icons';

const theme = createMuiTheme({
  typography: {
    "fontFamily": "\"MyCustomFont\"",
    "letterSpacing": 0.32,
    "fontFamily": "Hind",
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
});

const styles = {
  // This group of buttons will be aligned to the right
  rightToolbar: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyCointent: 'center',
    alignItems: 'center'
  },
  title: {
    marginLeft: '2%',
  },
  leftToolBar: {
    marginLeft: '1VW',
    paddingLeft: '1VW',
    paddingRight: '1.5VW',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    margin: '2%',
    paddingLeft: '1VW',
    paddingRight: '1VW',
    width: '11VW',
    '&:hover':{
      color:'#6E6EFA !important'
    },
  },
  viewButtonLabel:{
    textTransform: "none"
  },
  rightToolBarButton:{
    margin: '2%',
    width:"90px",
    paddingTop:'0.5VW',
    paddingBottom:'0.5VW',
    backgroundColor:'#6E6EFA',
    color:'white !important',
    borderRadius:'8px',
    fontWeight:'bolder',
    fontSize:'11px',
    letterSpacing:'0.7px',
    '&:hover':{
      backgroundColor:'white',
      color:'#6E6EFA !important'
    },
  },
  mainBar: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor:'#ECF1F4'
  },
  MenuBarItem: {
    '&:hover':{
      color:'#6E6EFA !important'
    },
  },
};



class Navbar extends Component {

  state = {
    anchorEl: null,
    anchorOriginVertical: 'bottom',
    anchorOriginHorizontal: 'right',
    transformOriginVertical: 'top',
    transformOriginHorizontal: 'right',
    anchorReference: 'anchorEl',
  };

  handleMenu = event =>{
    this.setState({ anchorEl:event.currentTarget });
  };

  handleClose = () =>{
    this.setState({anchorEl:null});
  };

  render() {
  
    const { classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <AppBar position="static" elevation={0}>
        <Toolbar className={classes.mainBar}>
          <ThemeProvider theme={theme}>
            <div className="title-desktop">
              <Typography className={classes.title}>
                <a href="/"><img src={logo} height={45} /></a>
              </Typography>
              <section className={classes.leftToolBar}>
                <Button className={classes.button} color="inherit" component={Link} to="/aboutus" classes={{label:classes.viewButtonLabel}}>
                  <b>About</b>
                </Button>
                <Button className={classes.button} color="inherit" component={Link} to="/faq" classes={{label:classes.viewButtonLabel}}>
                  <b>FAQ</b>
                </Button>
                <Button className={classes.button} color="inherit" component={Link} to="/support" classes={{label:classes.viewButtonLabel}}>
                  <b>Support</b>
                </Button>
                <Button className={classes.button} color="inherit" component={Link} to="/partners" classes={{label:classes.viewButtonLabel}}>
                  <b>Partners</b>
                </Button>
              </section>
              <section className={classes.rightToolbar}>
                <Button 
                  className={classes.rightToolBarButton} 
                  variant='outlined' 
                  color='inherit'
                  disableElevation 
                  component={Link} to="/signup"
                >
                  Sign up
                </Button>
                <Button 
                  className={classes.rightToolBarButton} 
                  variant='outlined' 
                  color='inherit' 
                  disableElevation 
                  component={Link} to="/login" 
                >
                  Login
                </Button>
              </section>
            </div>
          </ThemeProvider>



          <ThemeProvider theme={theme}>
            <div className="title-mobile">

              <section className={classes.leftToolBar}>
                <IconButton aria-controls="menu" aria-haspopup="true" onClick={this.handleMenu}>
                  <List />
                </IconButton>
                <Menu
                  id = "menu"
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  open= {Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose} component={Link} to="/" className={classes.MenuBarItem}>Home</MenuItem>
                  <MenuItem onClick={this.handleClose} component={Link} to="/aboutus" className={classes.MenuBarItem}>About</MenuItem>
                  <MenuItem onClick={this.handleClose} component={Link} to="/faq" className={classes.MenuBarItem}>FAQ</MenuItem>
                  <MenuItem onClick={this.handleClose} component={Link} to="/support" className={classes.MenuBarItem}>Support</MenuItem>
                  <MenuItem onClick={this.handleClose} component={Link} to="/partners" className={classes.MenuBarItem}>Partners</MenuItem>
                </Menu>
              </section>

              <a href="/"><img src={logo} height={30} /></a>

              <section className={classes.rightToolbar}>
                <Button 
                  style={{width:"90px"}} 
                  className={classes.rightToolBarButton} 
                  variant='outlined' 
                  disableElevation 
                  component={Link} to="/signup" 
                >
                  Sign up
                </Button>

                <Button 
                  style={{ marginLeft: "3%", width:"90px"}} 
                  className={classes.rightToolBarButton} 
                  variant='outlined' 
                  disableElevation 
                  component={Link} to="/login" 
                >
                    Log In
                </Button>
              </section>
            </div>
          </ThemeProvider>
        </Toolbar>
      </AppBar >
    );
  }
}

export default withStyles(styles)(Navbar);