import 'whatwg-fetch';
import React from 'react';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Scheduler from 'devextreme-react/scheduler';
import { CheckBox } from 'devextreme-react/check-box';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import SideBar from '../components/Sidebar'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import { fontWeight, fontSize } from '@material-ui/system';
import { CaretDownSquareFill } from 'react-bootstrap-icons';

var gapi = window.gapi
/* 
  Update with your own Client Id and Api key 
*/
var CLIENT_ID = "632959716758-df1k6heokqvqs3bkl6dasoto32ol82rt.apps.googleusercontent.com"
var API_KEY = "AIzaSyCMH3sk84ydV-mpYW4AHUIWXLjLRHLwfEA"
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
var SCOPES = "https://www.googleapis.com/auth/calendar.events"

const handleClick = () => {
  gapi.load('client:auth2', () => {
    console.log('loaded client')

    gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    })
    gapi.client.load('calendar', 'v3', () => console.log('bam!'))
    gapi.auth2.getAuthInstance().signIn()
  })
}


function handleErrors(response) {
  if (!response.ok)
    throw Error(response.statusText);
  return response;
}

function getData(_, requestOptions) {
  // const PUBLIC_KEY = 'AIzaSyBnNAISIUKe6xdhq1_rjor2rxoI3UlMY7k',
  //   CALENDAR_ID = 'f7jnetm22dsjc3npc2lu3buvu4@group.calendar.google.com';
  const PUBLIC_KEY = 'AIzaSyCMH3sk84ydV-mpYW4AHUIWXLjLRHLwfEA', //Huddl API Key
    CALENDAR_ID = 'hellohuddl@gmail.com';
  const dataUrl = ['https://www.googleapis.com/calendar/v3/calendars/',
    CALENDAR_ID, '/events?key=', PUBLIC_KEY].join('');

  return fetch(dataUrl, requestOptions).then(
    (response) => response.json()
  ).then((data) => data.items);
}

const dataSource = new CustomStore({
  load: (options) => getData(options, { showDeleted: false }),
  remove: (key) => {
    const PUBLIC_KEY = 'AIzaSyCMH3sk84ydV-mpYW4AHUIWXLjLRHLwfEA', //Huddl API Key
      CALENDAR_ID = 'hellohuddl@gmail.com';
    console.log("encodeURIComponent(key)", encodeURIComponent(key));
    console.log("(key)", key);
    console.log("(key.id)", key.id);
    handleClick()
    return fetch(`https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events/${(key.id)}`, {
      method: "DELETE"
    })
      // return fetch(`https://mydomain.com/MyDataService/${encodeURIComponent(key)}`, {
      //     method: "DELETE"
      // })
      .then(handleErrors);
  }
});

const currentDate = new Date(2020, 8, 22);
const views = ['day', 'workWeek', 'month'];

const theme = createMuiTheme({
  typography: {
    "fontFamily": "\"MyCustomFont\"",
    "letterSpacing": 0.32,
    "fontFamily": "Hind",
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
  palette: {
    background: {
      default: "#F7F7FF"
    }
  }
});

const styles = {
  addEventButton:{
    marginLeft:'100px',
    marginBottom:'20px',
    width:"140px",
    paddingTop:'0.5VW',
    paddingBottom:'0.5VW',
    backgroundColor:'#6E6EFA',
    color:'white !important',
    borderRadius:'8px',
    fontWeight:'bolder',
    fontSize:'15px',
    letterSpacing:'0.7px',
    '&:hover':{
      backgroundColor:'white',
      color:'#6E6EFA !important'
    },
  },
};

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowAdding: true,
      allowDeleting: true,
      allowResizing: true,
      allowDragging: true,
      allowUpdating: true
    };
    this.onAllowAddingChanged = this.onAllowAddingChanged.bind(this);
    this.onAllowDeletingChanged = this.onAllowDeletingChanged.bind(this);
    this.onAllowResizingChanged = this.onAllowResizingChanged.bind(this);
    this.onAllowDraggingChanged = this.onAllowDraggingChanged.bind(this);
    this.onAllowUpdatingChanged = this.onAllowUpdatingChanged.bind(this);
    this.showAddedToast = this.showAddedToast.bind(this);
    this.showUpdatedToast = this.showUpdatedToast.bind(this);
    this.showDeletedToast = this.showDeletedToast.bind(this);
  }

  routeChange() {
    let path = `/PrivacyPolicy`;
    this.history.push(path);
  }

  btnClick() {
    window.open("https://www.iubenda.com/privacy-policy/30596623");
  }

  render() {
    const { classes } = this.props;
    return (
      //   <div><SideBar /></div>
      <React.Fragment>
        <SideBar />
        <ThemeProvider theme={theme}>
        <CssBaseline />
          <div className="long-title"
            style={{
              marginLeft: '100px',
              marginTop:'40px',
              fontWeight: '200',
              fontSize: '80px',
              color:'black'
            }}>
            <h3><b>Calendar</b></h3>
          </div>
          <Button 
            component={Link} to="/CalendarAddEvent"
            variant='outlined' 
            color='inherit'
            disableElevation 
            className={classes.addEventButton}
          >
            <b>ADD AN EVENT</b>
          </Button>
          <div style={{ 
            marginLeft: '100px',
            marginRight: '60px' }}>
            <Scheduler
              dataSource={dataSource}
              views={views}
              defaultCurrentView="week"
              defaultCurrentDate={currentDate}
              height={500}
              startDayHour={7}
              editing={false}
              showAllDayPanel={false}
              startDateExpr="start.dateTime"
              endDateExpr="end.dateTime"
              textExpr="summary"
              timeZone="America/Los_Angeles"
              editing={this.state}
              onAppointmentAdded={this.showAddedToast}
              onAppointmentUpdated={this.showUpdatedToast}
              onAppointmentDeleted={this.showDeletedToast}
            />
            <div className="options">
              <div className="caption"></div>
            {/* <div className="option">
            <CheckBox
              defaultValue={this.state.allowAdding}
              text="Allow adding"
              onValueChanged={this.onAllowAddingChanged}
            />
          </div>
          {' '} */}
            {/* <div className="option">
              <CheckBox
                defaultValue={this.state.allowDeleting}
                // text="Allow deleting"
                text="Refresh"
                onValueChanged={this.onAllowDeletingChanged}
              />
            </div> */}
            <div>
              <Button variant='outlined' onClick={event => window.location.href = '/PrivacyPolicy'}>Privacy Policy</Button>
              {/* <Button cssClass='e-link' onClick={this.btnClick.bind(this)}>Privacy Policy</Button> */}
            </div>
            {/* <div>*Privacy Policy for using Google Calendar API:</div>
            <div>How Huddle website will access Google user data: </div>
            <div>We will only get the event details including start time, end time, description, recurrence and invitee emails from the Google user. </div>

            <div>How Huddl website will use Google user data: </div>
            <div>All the data access from Google user will be used to display in our own calendar page. Our calendar page will allow users to delete a selected Google event and add an event to their Calendar.</div>

            <div>How Huddl website will store Google user data:</div>

            <div>The data retrived from the Google user data will NOT be stored anyway in the system. It will be retrieved after the user logged in and authorized us to edit their events. No data will be stored.</div>

            <div>How Huddl website will share Google user data:</div>

            <div>The Huddl will not share any Google user data with anyone. This is a tool for the users to manager their own events and no one else can see their data.</div> */}

            {/* </div> */}
            {/* {' '}
          <div className="option">
            <CheckBox
              defaultValue={this.state.allowUpdating}
              text="Allow updating"
              onValueChanged={this.onAllowUpdatingChanged}
            />
          </div>
          {' '}
          <div className="option">
            <CheckBox
              defaultValue={this.state.allowResizing}
              text="Allow resizing"
              onValueChanged={this.onAllowResizingChanged}
              disabled={!this.state.allowUpdating}
            />
          </div>
          {' '}
          <div className="option">
            <CheckBox
              defaultValue={this.state.allowDragging}
              text="Allow dragging"
              onValueChanged={this.onAllowDraggingChanged}
              disabled={!this.state.allowUpdating}
            />
          </div> */}
          </div>
        </div>
      </ThemeProvider>
      </React.Fragment>

    );
  }

  onAllowAddingChanged(e) {
    this.setState({ allowAdding: e.value });
  }

  onAllowDeletingChanged(e) {
    this.setState({ allowDeleting: e.value });
  }

  onAllowResizingChanged(e) {
    this.setState({ allowResizing: e.value });
  }

  onAllowDraggingChanged(e) {
    this.setState({ allowDragging: e.value });
  }

  onAllowUpdatingChanged(e) {
    this.setState({ allowUpdating: e.value });
  }

  showToast(event, value, type) {
    notify(`${event} "${value}" task`, type, 800);
  }

  showAddedToast(e) {
    this.showToast('Added', e.appointmentData.text, 'success');
  }

  showUpdatedToast(e) {
    this.showToast('Updated', e.appointmentData.text, 'info');
  }

  showDeletedToast(e) {
    this.showToast('Deleted', e.appointmentData.text, 'warning');
  }

}

export default withStyles(styles)(Calendar);

