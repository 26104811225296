import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/styles/withStyles';
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Select } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';


// actions
import { clearSnackMessage } from '../actions/uiActions'

// thunks
import { requestPasswordChange, signupUser } from '../thunks';

// selectors
import {
	selectLoadingUI,
	selectErrorUI,
	selectNgoId
} from '../selectors'
// Styles


const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#ffcc99'
      },
      secondaryText: {
        main: "blue"
      }
    }
  });
const styles = {
	root:{
		width: '100%',
    boxSizing: 'border-box',
    marginLeft: 'auto',
	marginRight: 'auto',
	paddingLeft:'8.4%',
	paddingRight:'8.4%',
	},
	mainClass: {
		fontFamily: "Hind",
		width: '100%',
		height: '20%',
		color: 'black',
	},
	submit: {
		boxShadow: 'none',
		fontFamily: "Hind",
	},
	notchedOutline: {
		borderWidth: "2px",
		borderColor: "#6e6efa !important",
	}
	
}
const PurpleSwitch = withStyles({
	switchBase: {
		color: '#6e6eFA',
		'&$checked': {
			color: '#43AA8B',
		},
		'&$checked + $track': {
			backgroundColor: '#72BFA8',
		},
	},
	checked: {},
	track: {},
})(Switch);

export class SignupBox extends Component {
	constructor() {
		super();
		this.state = {
			handle: '',
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			confirmPassword: '',
			isTherapist: false,
			userGroup: "",
			termsOfUse: false,
			privacyPolicy: false,
		}
	}

	handleChange = (event) => {
		if (event.target.name === "isTherapist") {
			this.setState({[event.target.name]: !this.state.isTherapist})
		} else if (event.target.name === "termsOfUse") {
			this.setState({[event.target.name]: !this.state.termsOfUse})
		} else if (event.target.name == "privacyPolicy") {
			this.setState({[event.target.name]: !this.state.privacyPolicy})
		} else {
			this.setState({
				[event.target.name]: event.target.value
			});
		}
		
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const userData = {
			handle: this.state.email,
			email: this.state.email,
			password: this.state.password,
			confirmPassword: this.state.confirmPassword,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			userGroup: this.state.isTherapist ? "Therapist" : "Volunteer",
			termsOfUse: this.state.termsOfUse,
			privacyPolicy: this.state.privacyPolicy,
			ngoId: this.props.ngoId,
			matchedTherapist: false,
		}
		console.log(this.props)
		this.props.submitNewUser(userData, this.props.history)
	}
	componentDidUpdate() {
		//console.log(this.state)
	}

	render() {

		const { classes, signUpUser, errorMessage, isLoading } = this.props;

		return (
			<>
			{errorMessage ?
                    <SnackbarContent
						margin="normal"
                        message={errorMessage}
						style={{minWidth: 300, maxWidth: 350}}
                    >    
                    </SnackbarContent>
                    : null
                }
				<Container component="main" 
					class={{
						root:classes.root
					}}
					style={{ paddingRight:'8.4%', paddingLeft:'8.4%'}}
				>
					<form noValidate onSubmit={this.handleSubmit} >
						<FormControl fullWidth className={classes.margin} variant="outlined"
						>
							<TextField
								autoComplete="fname"
								name="firstName"
								variant="outlined"
								margin="normal"
								onChange={this.handleChange}
								required
								fullWidth
								id="firstName"
								label="First Name"
								className={classes.textField}
								style={{ minWidth: 400, maxWidth: 500, }}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
									}
								}}
								InputLabelProps={{
									style: {
										color: "#6e6efa",
									}
								}}
							/>
						</FormControl>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="lastName"
								label="Last Name"
								onChange={this.handleChange}
								name="lastName"
								autoComplete="lname"
								className={classes.textField}
								style={{ minWidth: 400, maxWidth: 500, }}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
									}
								}}
								InputLabelProps={{
									style: {
										color: "#6e6efa",
									}
								}}
							/>
						</FormControl>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								onChange={this.handleChange}
								label="Email Address"
								name="email"
								autoComplete="email"
								className={classes.textField}
								style={{ minWidth: 400, maxWidth: 500, }}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
									}
								}}
								InputLabelProps={{
									style: {
										color: "#6e6efa",
									}
								}}
							/>
						</FormControl>

						<FormControl fullWidth className={classes.margin} variant="outlined">
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								onChange={this.handleChange}
								type="password"
								id="password"
								autoComplete="current-password"
								className={classes.textField}
								style={{ minWidth: 400, maxWidth: 500, }}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
									}
								}}
								InputLabelProps={{
									style: {
										color: "#6e6efa",
									}
								}}
							/>
						</FormControl>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="confirmPassword"
								label="Confirm Password"
								onChange={this.handleChange}
								type="password"
								id="confirmPassword"
								autoComplete="current-password"
								className={classes.textField}
								style={{ minWidth: 400, maxWidth: 500, }}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
									}
								}}
								InputLabelProps={{
									style: {
										color: "#6e6efa",
									}
								}}
							/>
						</FormControl>
						
						<span>{"Volunteer"}</span>
							<FormControlLabel
								style={{
									marginLeft: 10,
								}}
								control={
								<PurpleSwitch checked={this.state.isTherapist} 
									onChange={this.handleChange} name="isTherapist" 
								/>}
							/>
						<span>{"Therapist"}</span>

						<FormControl fullWidth className={classes.margin} variant="outlined">

							<FormControlLabel
								control={ 
									<Checkbox
										name="termsOfUse"
										value= {this.state.termsOfUse} 
										color="primary"
										onChange={this.handleChange}
								/>}
								label="I agree to Huddl's Terms of Use"
							/>
						</FormControl>
						<FormControl fullWidth className={classes.margin} variant="outlined">

							<FormControlLabel
								control={ 
									<Checkbox
										name="privacyPolicy"
										value= {this.state.privacyPolicy} 
										color="primary"
										onChange={this.handleChange}
								/>}
								label="I agree to Huddl's Privacy Policy"
							/>
						</FormControl>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<Button
								type="submit"
								style={{
									width: 250,
									marginTop: 10,
								}}
								onClick={this.handleSubmit}
								variant="contained"
								color="primary"
								disabled={
									!this.state.email ||
									!this.state.password ||
									!this.state.confirmPassword ||
									!this.state.firstName ||
									!this.state.lastName ||
									!this.state.termsOfUse ||
									!this.state.privacyPolicy ||
									!this.props.ngoId
								}
								className={classes.submit}
							>
								<b>Submit</b>
							</Button>
							{isLoading ?
                                <MuiThemeProvider theme={theme}>
                                    <LinearProgress 
                                        color="secondary"
                                        style={{
                                            width: 250,
                                        }} 
                                    />
                                </MuiThemeProvider>
                            : null
                            }      
						</FormControl>
					</form>
					<Box mt={15}>
					</Box>
				</Container>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	isLoading: selectLoadingUI(state),
	errorMessage: selectErrorUI(state),
	ngoId: selectNgoId(state),
})

const mapDispatchToProps = (dispatch) => ({
	submitNewUser: (newUserData, history) => {
		dispatch(signupUser(newUserData, history))
	},
	clearErrors: () => {
		dispatch(clearSnackMessage())
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignupBox))
