import {
    SET_USER, 
    SET_AUTHENTICATED, 
    SET_UNAUTHENTICATED,
    SET_NGO,
    SET_VALID_NGO,
    SET_MATCHINGS,
} from '../constants/userConstants';

const initialState = {
    // state vars used after successful login
    authenticated: false,
    credentials: {},
    matchings: false,
    // state vars used during sign up
    ngoId: false,
    ngoIdValid: false,
}

export default function(state= initialState, action) {
    switch(action.type) {
        case SET_AUTHENTICATED:
            return {
                ...state, 
                authenticated: true
            };
        case SET_UNAUTHENTICATED:
            return initialState;
        case SET_USER: 
            return {
                authenticated: true,
                ...action.payload
            }
        case SET_NGO:
            return {
                ...state,
                ngoId: action.payload
            }
        case SET_VALID_NGO:
            return {
                ...state,
                ngoIdValid: true
            }
        case SET_MATCHINGS:
            return {
                ...state,
                matchings: action.payload
            }
        default:
            return state;
    }
}