import React, { Component } from 'react'
import homepage1 from '../image/homepage-1.png';
import homepage2 from '../image/homepage-2.png';
import homepage3 from '../image/homepage-3.png';
import homePage from '../image/huddl.png';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

// Material UI Imports
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import '../css/home.css';

// Styles
var panelViewContainer = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#F7F7FF",
}
var welcome = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100VH',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundImage: `url(${homePage})`,
    backgroundRepeat: 'no-repeat', 
    backgroundSize: 'cover',
}
var panelContainer = {
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    height: '100VH',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '1.0',
}

var sides = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: '100%',
    width: 0,
    padding: '3%',
    
}

let theme = createMuiTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(','),

        h1: {
            fontSize: 3,
            color:"white",
            textAlign: "center",
            position:"relative",
            top:-30,
            
        },
        h2: {
            fontSize: 3,
            color:"#6E6EFA",
            textAlign: "center",
            position:"relative",
            top:-40, 
            fontWeight: "bold"

        },
        h3: {
            fontSize: 2.5,
            textAlign: "left"
        },
        //text:
        h4: {
            fontSize: 1.5,
            textAlign: "left",
            color: "black",
        },
        h5: {
            fontSize: 1.5,
            textAlign: "left",
            position:"relative",
            top:10
           
        },
    },
});
theme = responsiveFontSizes(theme);

export class InfoPanels extends Component {
    constructor() {
        super();

        this.state = {
            infoPanels: [
            {
                heading_top: "Connecting Mental Health Providers with Aid Workers on the Ground.",
                subheading_top: "Want to learn more about how you can help?",             
            }, {
                heading: "1 ➞ 100",
                image: homepage1,
               
                text: 'With alarming rates of anxiety, PTSD, burnout and other mental health disorders,volunteers need help in order to perform at their highest potential. There currently aren\'t enough efforts to care for them, which negatively impacts the quality of care for the refugees and  causes high turnover rates.'
            }, {
                heading: "Are you a mental health provider?",
                image: homepage2,
                text: 'We hope to provide volunteers with 1-hour video coaching sessions with a mental health provider every other week. This is a great opportunity to directly aid the refugee crisis with the knowledge you’ve acquired in your career. Contact us at hellohuddl@gmail.com to get involved.'
            },
            {
                heading: "Are you a volunteer?",
                image: homepage3,
                text: 'In a recent study, 72.8% of rescue workers on Lesvos reported low levels of well-being, with over showing symptoms of burnout syndrome. Telecounseling has been proven to help alleviate stress and combat burnout. Help us enable you to become the most effective volunteer you can be.'
            }]
        }
    }


    loadPanels = (arr) => {
        var counter = 1
        var rows = []
        for (let i = 0; i < arr.length; i++) {
            let object = arr[i]
            var heading_top = object.heading_top
            var heading = object.heading
            var subHeading = object.subHeading
            var text = object.text
            var image = object.image
            var subheading_top = object.subheading_top
            var image_right = object.image_right
 
            if (counter === 1) {    
                rows.push(
                    <ThemeProvider theme={theme}>
                         <div className="mobile">
                                {/* <div style={sides}> */}
                                    {/* big heading */}
                                    {/* <Typography variant='h1'> */}
                                    <div className="title">
                                        {heading_top}
                                    </div>
                                    {/* <Typography variant='h2' >
                                        {heading}
                                    </Typography> */}
                                    <Typography variant='h5' style={{paddingRight: 40}}>
                                        {subheading_top}
                                    </Typography>
                                    <img src={image} width="250"/>
                                    <Link to="../aboutus" style={{ paddingRight: 330,paddingTop:40,color:"#43AA8B"}}>
                                        <Button size="large"
                                            variant="contained"
                                            color="#43AA8B"
                                            disableElevation
                                            >Read More
                                        </Button>
                                    </Link>
                                 {/* </div> */}
                                <div style={sides}>
                                    <Typography variant='h3'>
                                        {subHeading}
                                    </Typography>
                                    <Typography variant='h4'>
                                        {text}
                                    </Typography>
                                    <img src={image_right} width="250"/>
                                </div>
                            </div>
                            <div className="desktop">
                                <div style={sides}>
                                    <Typography variant='h1'>
                                        {heading_top}
                                    </Typography>
                                    <Typography variant='h2'>
                                        {heading}
                                    </Typography>
                                    <Typography variant='h5'>
                                        {subheading_top}
                                    </Typography>
                                    <img src={image} width="250"/>
                                    <Link to="/aboutus" style={{ padding: 40 }}>
                                        <Button size="large"
                                            variant="contained"
                                            color="#43AA8B"
                                            disableElevation
                                            >Read More
                                        </Button>
                                    </Link>
                                </div>
                                <div style={sides}>
                                    <Typography variant='h3'>
                                        {subHeading}
                                    </Typography>
                                    <Typography variant='h4'>
                                        {text}
                                    </Typography>
                                    <img src={image_right} width="250"/>
                                </div>
                            </div>
                    </ThemeProvider>
                )
                counter ++;
                }else{
                    rows.push(
                        <ThemeProvider theme={theme}>
                            <div className="desktopInfo">
                                    <div style={sides}>
                                        <Typography variant='h2'>
                                            {heading}
                                        </Typography>
                                        <img src={image} width="250"/>
                                        
                                    </div>
                                    <div style={sides}>
                                        <Typography variant='h4'>
                                            {text}
                                        </Typography>
                                    </div>
                            </div>
                            <div className="mobInfo">
                                    <div style={sides}>
                                    <img src={image} width="100" style={{textAlign: "center", paddingBottom:"30px"}}/>
                                    <br></br>
                                        <Typography variant='h2' style={{fontSize: "40px"}}>
                                            {heading}
                                        </Typography>
                                        <Typography variant='h4' style={{textAlign: "center"}}>
                                            {text}
                                        </Typography>
                                    </div>
                                    {/* <div style={sides}>
                                        
                                    </div> */}
                            </div>
                        </ThemeProvider>
                    )
                    counter++;

                }
           

        }
        return rows
    }

    render() {
        return (
           
            <div style={panelViewContainer}>
               
                    {this.loadPanels(this.state.infoPanels)}
               
            </div>
           
            
        )
    }
}

export default InfoPanels