// Material-UI Imports
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Color from 'color'
import { CardHeader } from '@material-ui/core';
import { Select } from '@material-ui/core';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Checkbox from '@material-ui/core/Checkbox';
import React, { Component } from 'react';




class MatchingForm extends React.Component {
    constructor() {
        super();

        this.state = {

        };
    }
    handleSubmit = () => {
        
        const surveyData = {

        }
        this.props.callBackFromSurvey();

    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleWeightChange = name => (event, value)  => {
        this.setState({
            [name]: value
        });
        console.log(this.state)
    }



    render() {
        return (
            <Container
                style={{
                    font: "Hind",
                }}
            >
                <FormControl 
                    variant="outlined"
                    style={{
                        display: "flex",
                        padding: 25,
                        background: "#EEF0F2",
                        borderRadius: 25,
                    }}    
                >
                <FormControlLabel
                    control={ 
                        <Checkbox
                            style={{
                                marginLeft: "auto"
                            }}
                            name="termsOfUse"
                            value= {this.state.termsOfUse} 
                            color="primary"
                            onChange={this.handleChange}
                    />}
                    label="Select this provider"
                /> 
                <CardHeader
                    title={`Provider #1`}
                    subheader={"Match Score: " + "99/100"}
                    style={{
                        display: "flex",
                        textAlign: "left",
                        marginTop: -50,
                        marginLeft: 15,
                        fontFamily: "Hind",
                    }}
                    
                    titleTypographyProps={{variant:'h4' }}
                    subheaderTypographyProps={{variant:'h5'}}
                >
                </CardHeader>
                
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. 
                Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. 
                Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, 
                imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer 
                tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. 
                Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, 
                viverra quis, feugiat a,


                </FormControl>
                
                <FormControl 
                    fullWidth 
                    variant="outlined"
                    style={{
                        padding: 25,
                        backgroundColor: Color("#6E6EFA").alpha(0.5).string(),
                        borderRadius: 25,
                        marginTop: 25,
                    }}    
                >   
                                <FormControlLabel
                    control={ 
                        <Checkbox
                            style={{
                                marginLeft: "auto"
                            }}
                            name="termsOfUse"
                            value= {this.state.termsOfUse} 
                            color="primary"
                            onChange={this.handleChange}
                    />}
                    label="Select this provider"
                /> 
                    <CardHeader
                    title={"Provider #2"}
                    subheader={"Match Score: 96/100"}
                    style={{
                        textAlign: "left",
                        marginTop: -50,
                        marginLeft: 15,
                        fontFamily: "Hind",
                    }}
                    titleTypographyProps={{variant:'h4' }}
                    subheaderTypographyProps={{variant:'h5'}}
                >
                </CardHeader>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. 
                Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. 
                Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, 
                imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer 
                tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. 
                Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, 
                viverra quis, feugiat a,

                </FormControl>
                <FormControl 
                    fullWidth 
                    variant="outlined"
                    style={{
                        padding: 25,
                        background: "#EEF0F2",
                        borderRadius: 25,
                        marginTop: 25,
                    }}    
                >
                                    <FormControlLabel
                    control={ 
                        <Checkbox
                            style={{
                                marginLeft: "auto"
                            }}
                            name="termsOfUse"
                            value= {this.state.termsOfUse} 
                            color="primary"
                            onChange={this.handleChange}
                    />}
                    label="Select this provider"
                /> 
                    <CardHeader
                    title={"Provider #3"}
                    subheader={"Match Score: " + "88/100"}
                    style={{
                        textAlign: "left",
                        marginTop: -50,
                        marginLeft: 15,
                        fontFamily: "Hind",
                    }}
                    titleTypographyProps={{variant:'h4' }}
                    subheaderTypographyProps={{variant:'h5'}}
                >
                </CardHeader>

                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. 
                Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. 
                Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, 
                imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer 
                tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. 
                Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, 
                viverra quis, feugiat a,

                </FormControl>


                <FormControl
                    style={{ display: "flex" }}
                >
                    
                    <Button 
                        
                        variant="contained" 
                        color="primary" 
                        onClick={this.handleSubmit} 
                        type="submit" 
                        style={{ marginLeft: "auto", marginTop: "25px", marginBottom: "25px" }}
                        disabled={true}
                    >
                        Submit
                    </Button>
                </FormControl>
            </Container>
        )
    }
}

export default MatchingForm;