import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import SiteMap from '../components/SiteMap'
import '../css/aboutus.css';

class partners extends Component {

    render() {
        const rightColumn = {
            backgroundColor: "#F7F7FF",
          };
        const leftColumn = {
            backgroundColor: "#F7F7FF",
        };
        const title ={
            color:"#6E6EFA",
            fontWeight: "bold",
        }
        const background ={
            backgroundColor: "#F7F7FF",
        }
        const text={
            color:"black",
        }
        return (
            <div style={background}>
                <Navbar />
                <div className="display-desktop">
                    <div className="titlee" style={title}>Partners</div>
                    <div className="container">
                        <div className="leftColumn" style={leftColumn}>                
                            <br></br>
                            <div style={text}>
                                We're in the process of launching our beta test in Lesvos, Greece and growing our network.
                                <br></br><br></br>
                                If you're interested in joining us, please contact us at <b>hellohuddl@gmail.com</b>
                            </div>
                        </div>
                        <div className = "rightColumn" style={rightColumn}>
                            <img className ="image" src="https://img.icons8.com/ios-glyphs/300/000000/meeting.png"/>
                        </div>
                    </div>
                </div>
                
                <div className="display-mobile">
                    <div className = "container">
                        <div className="title" style={title}>Partners</div>
                        <div className = "rightColumn" style={rightColumn}>
                            <img className="image" src="https://img.icons8.com/ios-glyphs/300/000000/meeting.png"/>
                        </div>
                    </div>
                        <div className="leftColumn" style={leftColumn}>                
                            <br></br><br></br>
                            <div style={text}>
                            We're in the process of launching our beta test in Lesvos, Greece and growing our network.
                            <br></br><br></br>
                            If you're interested in joining us, please contact us at <b>hellohuddl@gmail.com</b></div>
                        </div>
                   
                </div>
                <SiteMap />
            </div>

        )
    }

}

export default partners;