import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import SiteMap from '../components/SiteMap'
import SignupBox from '../components/SignupBox'
import NgoModal from '../components/NgoModal'
import FormControl from '@material-ui/core/FormControl';
import { ThemeProvider as MuiThemeProvider, createMuiTheme, withTheme } from '@material-ui/core/styles';
import { CardHeader } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

// redux
import { connect } from 'react-redux';
import { signupUser } from '../thunks';

// selectors
import { 
    selectNgoId,
    selectValidNgoId,
 } from '../selectors'


// Styles
var container = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: "Hind",
    
}
const styles = theme => ({
    root:{
		display: 'flex',
    /* padding: 16px; */
    alignItems: 'center',
	},
    title: {
        color: 'black',
        fontWeight: 550,
        fontFamily: "Hind",
        
    },
    textheader: {
        color: 'black',
        fontFamily: "Hind",
    },
    input: {
        color: "white",
        backgroundColor: "#8c8cf5",
        fontFamily: "Hind",
    }
  });
  const THEME = createMuiTheme({
    typography: {
      "fontFamily": "Hind",
      "letterSpacing": 0.32,
        
      useNextVariants: true,
      suppressDeprecationWarnings: true,
      h2: {
        "fontWeight": 600,
        fontFamily: "Hind",
      },
    },
});

class signup extends Component {

    constructor() {
        super();
    }
    componentDidMount() {
        console.log(this.props.ngoId)
    }


    render() {
        const { classes } = this.props;
        const background ={
            backgroundColor: "#F7F7FF",
        }
        return (
            <div style={container, background}>
                <Navbar />
                <MuiThemeProvider theme={THEME}>
                <FormControl fullWidth variant="outlined" style={{ paddingRight:'8.4%', paddingLeft:'8.4%'}}>
                <CardHeader
                    class={{
						root:classes.root
					}}
					style={{ paddingRight:'8.4%', paddingLeft:'8.4%'}}
                    title={"Join The Huddl"}
                    subheader={"We're excited for you to get started! First, we need a little info about you."}
                    style={{
                        marginTop: 25,
                        color: 'black'
                    }}
                    subtitleStyle={{
                        marginTop: 25,
                        color: 'black'
                    }}
                    titleTypographyProps={{variant:'h2' }}
                    subheaderTypographyProps={{variant:'h6'}}
                >
                </CardHeader>
                </FormControl>
                </MuiThemeProvider>
                { this.props.ngoIdValid ? 
                    null :
                    <NgoModal />
                }
                
                
                <SignupBox history={this.props.history} />
                <SiteMap />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
    ngoId: selectNgoId(state),
    ngoIdValid: selectValidNgoId(state),
});

const mapActionsToProps = {
    signupUser
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(signup))