
import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import store from '../store';
import '../css/dashboard.css';


//Styles
const styles = {
  title:{
    fontSize:'55px',
  },
  middleDiv: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    width: '100%',
  },
  middleDivHeader: {
    backgroundColor: '#EFF0F2',
    borderRadius: '5px 5px 0px 0px',
    color: 'black',
    paddingLeft: '40px',
    paddingTop: '20px',
  },
  events: {
    backgroundColor: '#A1A2F7',
    width: '90%',
    borderRadius: '8px',
    color: 'white',
    padding: '12px',
    marginBottom: '15px',
  },
  messageDiv: {
    backgroundColor: '#88C6B4',
    width: '90%',
    borderRadius: '8px',
    color: 'white',
    padding: '12px',
    marginBottom: '15px',
  },
  messagesButton: {
    // marginLeft:'100px',
    backgroundColor: '#43AA8B',
    color: 'white !important',
    borderRadius: '8px',
    fontWeight: 'bolder',
    fontSize: '15px',
    letterSpacing: '0.7px',
    '&:hover': {
      backgroundColor: 'white',
      color: '#43AA8B !important'
    },
  },
  eventButton: {
    // marginLeft:'10px',
    backgroundColor: '#6E6EFA',
    color: 'white !important',
    borderRadius: '8px',
    fontWeight: 'bolder',
    fontSize: '15px',
    letterSpacing: '0.7px',
    '&:hover': {
      backgroundColor: 'white',
      color: '#6E6EFA !important'
    },
  },
  contentFeedBox: {
    marginBottom: '20px',
    marginRight: '40px',
    borderRadius: '8px',
    borderColor: 'black',
    borderStyle: 'solid',
    padding: '12px',
    borderWidth: 'thin',
    color: 'black',
  },
  contentFeedButton: {
    marginTop: '20px',
    backgroundColor: 'white',
    color: '#6E6EFA !important',
    borderRadius: '8px',
    fontWeight: 'bolder',
    fontSize: '12px',
    letterSpacing: '0.7px',
    '&:hover': {
      backgroundColor: '#6E6EFA',
      color: 'white !important',
    },
  },
}

var contentFeedForumContainer={
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '20px',
}

var recentPosts = {
  borderRadius: '0px 0px 5px 5px',
  backgroundColor: '#EFF0F2',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '40px',
  paddingTop: '15px',
  paddingBottom: '20px',
}

var columnAlignment = {
  display: "flex",
  flexDirection: "column",
};

var titleStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  color: 'black',
  marginBottom:'20px',
}

var calendarItemStyle = {
  display: 'flex',
  borderRadius: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: '20px',
  backgroundColor: '#F3F3F3',
  margin: '.2%',
  marginTop: "1.25%",
  marginBottom: '1.25%'
}

var bordered = {
  border: "1px solid black"
}

var containerDiv = {
  height: '100%',
  display: 'flex',
  padding:'8.5%',
}

class Dashboard extends Component {
  componentDidMount() {
    this.setTimes();
  }

  constructor() {
    super();
    this.state = {
      currentUserName: store.getState().user.credentials.firstName,
      currentTimeLocal: 0,
      currentTimeGreece: 7,
      calendarDate: new Date(),
      calendarRows: [],
      random:0,
      times: [
        "12",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11"
      ],
      schedulerData: [
        {
          startDate: "2018-10-31 10:00",
          endDate: "2018-10-31 11:00",
          title: "Meeting"
        },
        {
          startDate: "2020-02-18 18:00",
          endDate: "2020-02-18 20:00",
          title: "Go to a gym"
        }
      ]
    }
    this.handleFirst = this.handleFirst.bind(this);
    this.handleSecond = this.handleSecond.bind(this);
    this.handleThird = this.handleThird.bind(this);
  }

  // first button open window
  handleFirst(event){
    window.open("https://www.healthline.com/health/breathing-exercise");
  }

  handleSecond(event){
    window.open("https://greatist.com/health/how-to-calm-down");
  }

  handleThird(event){
    window.open("https://www.nimh.nih.gov/health/topics/coping-with-traumatic-events/index.shtml");
  }

  setTimes = () => {
    var d = new Date();
    var greeceD = new Date().toLocaleString("en-US", {
      timeZone: "Europe/Athens"
    });
    var convertedDate = new Date(greeceD);
    this.setState({
      localHours: d.getHours(),
      greeceHours: convertedDate.getHours(),
      localMinutes: d.getMinutes(),
      greeceMinutes: convertedDate.getMinutes(),
      localDate: d.getDate(),
      greeceDate: convertedDate.getDate(),
      localMonth: d.getMonth(),
      greeceMonth: convertedDate.getMonth()
    })
  }

  

  createCalendarLabel = (headerItem, hours, minutes, subtext, hoursProv, minuteProv, subtext2, actionGreen,
    actionBlack, actionGreen2) => {
    var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', , 'Oct', 'Nov', 'Dec']
    var minuteString = minutes;
    var timeOfDay_local = '';
    var timeOfDay_prov = '';
    if (minutes < 10) {
      minuteString = "0" + minutes;
    }
    // if (hours < 12) {
    //   timeOfDay_local = 'AM'
    // } else {
    //   timeOfDay_local = 'PM'
    // }
    // if (hoursProv < 12) {
    //   timeOfDay_prov = 'AM'
    // } else {
    //   timeOfDay_prov = 'PM'
    // }
    var grid = <Grid container item xs={12} spacing={1}>

      <Grid item xs={12} className="hindFont">
        <Typography variant='h5' style={{ color: "black", display: 'inline' }}>
          {headerItem + " "} </Typography>
        <Typography variant='h5' style={{ color: "#6E6EFA", display: 'inline' }}>
          {hours + ":" + minuteString + timeOfDay_local}</Typography>
        <Typography variant='h5' style={{ color: "black", display: 'inline' }}>
          {subtext}</Typography>
        <Typography variant='h5' style={{ color: "#6E6EFA", display: 'inline' }}>
          {hoursProv + ":" + minuteProv + timeOfDay_prov} </Typography>
        <Typography variant='h5' style={{ color: "black", display: 'inline' }}>
          {subtext2}
        </Typography>
        <Typography variant='h5' style={{ color: "#43AA8B", display: 'inline' }}>
          {actionGreen}
        </Typography>
        <Typography variant='h5' style={{ color: "black", display: "inline" }}>
          {actionBlack}
        </Typography>
        <Typography variant='h5' style={{ color: "#43AA8B", display: "inline" }}>
          {actionGreen2}
        </Typography>

      </Grid>
    </Grid>
    return grid
  }

  createTimeCalendar = (arr, timeEntered) => {
    var rows = [];
    var timeOfDay = '';
    for (var i = 0; i < arr.length; i++) {
      if (i < 12) {
        timeOfDay = 'am'
      } else {
        timeOfDay = 'pm'
      }
      var timeString = arr[i];
      if (i === timeEntered) {
        rows.push(
          <Grid item xs style={Object.assign({}, calendarItemStyle, bordered)}>
            {timeString}<br />
            {timeOfDay}
          </Grid>
        );
      } else {
        rows.push(
          <Grid item xs style={calendarItemStyle} className="hindFont">
            {timeString}
            <br />
            {timeOfDay}
          </Grid>
        );
      }
    }
    return rows
  }

  render() {
    const { classes } = this.props;
    return (
      <div style={Object.assign({}, containerDiv, columnAlignment)}>
        <div style={titleStyle} className="hindFont">
          <p className={classes.title}>
            Welcome, <b>{"\n" + this.state.currentUserName}!</b>
          </p>
          <p>
            {this.createCalendarLabel(
              "It's ",
              this.state.localHours,
              this.state.localMinutes,
              " here and ",
              this.state.greeceHours,
              this.state.greeceMinutes,
              " where your provider is. ",
              " Message them ",
              " or check out a ",
              " featured article."
            )}
          </p>
        </div>
        <div className="middleDiv">
          <div style={Object.assign({})} className="forumContainerOne">
            <div className={classes.middleDivHeader}>
              <h2>Upcoming appointments</h2>
            </div>
            <div style={recentPosts}>
              <div className={classes.events}>
                <div><b>Mon, 8/24 at 4:00PM </b></div>
                <div>Meeting with my provider</div>
              </div>
              <div className={classes.events}>
                <div><b>Mon, 8/31 at 4:00PM </b></div>
                <div>Meeting with my provider</div>
              </div>
              <Link to="../Calendar" >
                <Button size="large"
                  variant="contained"
                  disableElevation
                  className={classes.eventButton}
                >Go to Calendar →
                </Button>
              </Link>
            </div>
          </div>
          <div style={Object.assign({})} className="forumContainerTwo">
            <div className={classes.middleDivHeader}>
              <h2>Recent Messages</h2>
            </div>
            <div style={recentPosts}>
              <div className={classes.messageDiv}>
                <div><b>My Doctor</b></div>
                <div>Hi Rui, can you still make today?</div>
              </div>
              <div className={classes.messageDiv}>
                <div><b>My co-volunteer</b></div>
                <div>Hey Rui! Did you see the new work schedule?</div>
              </div>
              <div className={classes.messageDiv}>
                <div><b>My co-volunteer</b></div>
                <div>Hey Rui! Did you see the new work schedule?</div>
              </div>
              <Link to="../chatpage">
                <Button size="large"
                  variant="contained"
                  disableElevation
                  className={classes.messagesButton}
                >
                  Go to Messages →
                </Button>
              </Link>
            </div>
          </div>
          <div style={Object.assign({}, contentFeedForumContainer)}>
            <div className={classes.middleDivHeader}>
              <h2>Content Feed</h2>
            </div>
            <div style={recentPosts}>
              <div className={classes.contentFeedBox}>
                <div><h3>10 Breathing Excercises to Try: For Stress, Training & Lung Capacity</h3></div>
                <div>Healthline</div>
                <div>If you're looking to practice breathing exercises, here are 10. 
                  These are common techniques for lowering stress, meditating, and 
                  improving breathing function.</div>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={this.handleFirst}
                  className={classes.contentFeedButton}
                >
                  Go to Article
                </Button>
              </div>
            </div>
            <div style={recentPosts}>
              <div className={classes.contentFeedBox}>
                <div><h3>18 Ways to Calm Yourself Down Right Now</h3></div>
                <div>Greatist</div>
                <div>Anxiety is a bitch. We're here to help!</div>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={this.handleSecond}
                  className={classes.contentFeedButton}
                >
                  Go to Article
                </Button>
              </div>
            </div>
            <div style={recentPosts}>
              <div className={classes.contentFeedBox}>
                <div><h3>Coping with Traumatic Events</h3></div>
                <div>National Institute of Mental Health</div>
                <div>The National Institute of Mental Health conducts and 
                  supports research not only on a wide range of mental health disorders, 
                  but also on reactions to national crises and traumatic events.</div>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={this.handleThird}
                  className={classes.contentFeedButton}
                >
                  Go to Article
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default withStyles(styles)(Dashboard)