// Material-UI Imports
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MatchingForm from '../components/MatchingForm';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { CardHeader } from '@material-ui/core';
import React, { Component } from 'react';

// Redux stuff
import store from '../store';
import { connect } from 'react-redux'
import { submitSurvey } from '../thunks'
import axios from 'axios';
import { clearSnackMessage } from '../actions/uiActions'
// selectors
import { 
    selectLoadingUI, 
    selectErrorUI,
    selectUserCredentials,
    selectUserMatchings,
 } from '../selectors'


const styles = {
    container: {
        height: '100%',
    },
};
const THEME = createMuiTheme({
    typography: {
      "fontFamily": "\"MyCustomFont\"",
      "letterSpacing": 0.32,
      "fontFamily": "Hind",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
      h1: {
        "fontWeight": 600,
         color: "black",
      },
      h2: {
        "fontWeight": 600,
        "fontFamily": "Hind",
      },
      h4: {
        paddingBottom: "15px",
        color: "black"
      },
      h5: {
          color: "black"
      }
    },
});
class MatchingPage extends Component {

    constructor() {
        super();
        this.state = {
            
        }
    }
    componentDidMount() {
        console.log("Logging matchings...")
        console.log(this.props.userMatchings)
    }

    render() {
        const { classes, userCredentials } = this.props;
        

        return (
            <div>
                <MuiThemeProvider theme={THEME}>
                <FormControl fullWidth variant="outlined">
                <CardHeader
                    title={"You've Been Matched!"}
                    subheader="Take a moment to look through each provider bio and match score. When you’re ready, select the match that best fits you!"
                    style={{
                        textAlign: "left",
                        marginTop: 25,
                        marginLeft: 15,
                        marginBottom: 15,
                        fontFamily: "Hind",
                    }}
                    titleTypographyProps={{variant:'h1' }}
                    subheaderTypographyProps={{variant:'h5'}}
                >
                    
                </CardHeader>
                </FormControl>
                <MatchingForm></MatchingForm>
                </MuiThemeProvider>
                
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: selectLoadingUI(state),
    errorMessage: selectErrorUI(state),
    userCredentials: selectUserCredentials(state),
    userMatchings: selectUserMatchings(state),
})

const mapDispatchToProps = (dispatch) => ({
    clearErrors: () => {
        dispatch(clearSnackMessage())
    },
    handleSubmitSurvey: (surveyData, history) => {
        dispatch(submitSurvey(surveyData, history))
    }
})



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MatchingPage))
