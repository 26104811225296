import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import '../css/calendarAddEvent.css';
import Sidebar from '../components/Sidebar';
import { withStyles } from '@material-ui/core/styles';
import store from '../store';
import axios from 'axios';


const repeatFrequency = [
  'None',
  'Daily',
  'Weekly',
  'Monthly',
]

const PurpleTextField = withStyles({
  root: {
    '& label': {
      color: '#6E6EFA',
    },
    '& label.Mui-focused': {
      color: '#6E6EFA',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#6E6EFA',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#6E6EFA',
      },
      '&:hover fieldset': {
        borderColor: '#6E6EFA',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6E6EFA',
      },
    },
  },
})(TextField);

axios.defaults.baseURL = 'https://us-central1-letshuddl.cloudfunctions.net/api';


class addEvent extends Component {
  componentDidMount() {
    console.log(this.props);
    this.getContacts();
  }

  constructor() {
    super();
    this.state = {
      currentUser: {},
      users: [],
      eventName: '',
      startDate: '',
      endDate: '',
      repeat: '',
      invitees: '',
      notes: '',
      errors: {
        "eventName": "",
        "startDate": "",
        "endDate": "",
        "repeat": "",
        "invitees": "",
        "notes": ""
      }
    }
    //get Contacts
    this.getContacts = this.getContacts.bind(this);
    this.handleEventNameChange = this.handleEventNameChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleRepeatChange = this.handleRepeatChange.bind(this);
    this.handleInviteesChange = this.handleInviteesChange.bind(this);
    this.handleAddNotesChange = this.handleAddNotesChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getContacts() {
    const me = store.getState().user.credentials;
    const params = { handle: me.handle };
    axios.post('/contacts', params).then(userList => {
      this.setState({
        users: userList.data.filter(user => user.userID != me.userID)
      })
    });
  }

  setDate() {
    var newDate = new Date();
    var month = newDate.getMonth() + 1;
    var day = newDate.getDate();
    var hour = newDate.getHours();
    var minute = newDate.getMinutes();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    if (hour < 10) {
      hour = '0' + hour;
    }
    if (minute < 10) {
      minute = '0' + minute;
    }
    var datetime = newDate.getFullYear() + "-"
      + month + "-"
      + day + "T"
      + hour + ":"
      + minute;

    return datetime;
  }

  handleEventNameChange(event) {
    this.setState({ eventName: event.target.value });
  }

  handleStartDateChange(event) {
    this.setState({ startDate: event.target.value });
  }

  handleEndDateChange(event) {
    this.setState({ endDate: event.target.value });
  }

  handleRepeatChange(event) {
    this.setState({ repeat: event.target.value });
  }

  handleInviteesChange(event) {
    this.setState({ invitees: event.target.value })
  }

  handleAddNotesChange(event) {
    this.setState({ notes: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log("Event name:", this.state.eventName);
    console.log("Start Date:", this.state.startDate);
    console.log("End Date:", this.state.endDate);
    console.log("Repeat:", this.state.repeat);
    console.log("Invitees:", this.state.invitees);
    console.log("Notes:", this.state.notes);
  }


  render() {

    var gapi = window.gapi
    var CLIENT_ID = "632959716758-df1k6heokqvqs3bkl6dasoto32ol82rt.apps.googleusercontent.com"
    var API_KEY = "AIzaSyCMH3sk84ydV-mpYW4AHUIWXLjLRHLwfEA"
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
    var SCOPES = "https://www.googleapis.com/auth/calendar.events"

    const handleClick = () => {
      gapi.load('client:auth2', () => {
        console.log('loaded client')
  
        gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
  
        gapi.client.load('calendar', 'v3', () => console.log('bam!'))
  
        gapi.auth2.getAuthInstance().signIn()
        .then(() => {
          
          var event = {
            'summary': this.state.eventName,
            'location': '800 Howard St., San Francisco, CA 94103',
            'description': this.state.notes,
            'start': {
              'dateTime': this.state.startDate,
              'timeZone': 'America/Los_Angeles'
            },
            'end': {
              'dateTime': this.state.endDate,
              'timeZone': 'America/Los_Angeles'
            },
            'recurrence': [
              'RRULE:FREQ=DAILY;COUNT=2'
            ],
            'attendees': [
              {'email': 'lpage@example.com'},
              {'email': 'sbrin@example.com'}
            ],
            'reminders': {
              'useDefault': false,
              'overrides': [
                {'method': 'email', 'minutes': 24 * 60},
                {'method': 'popup', 'minutes': 10}
              ]
            }
          }
  
          var request = gapi.client.calendar.events.insert({
            'calendarId': 'primary',
            'resource': event,
          })
  
          request.execute(event => {
            console.log(event)
            window.open(event.htmlLink)
          })
            
          /*
              Uncomment the following block to get events
          */
          /*
          // get events
          gapi.client.calendar.events.list({
            'calendarId': 'primary',
            'timeMin': (new Date()).toISOString(),
            'showDeleted': false,
            'singleEvents': true,
            'maxResults': 10,
            'orderBy': 'startTime'
          }).then(response => {
            const events = response.result.items
            console.log('EVENTS: ', events)
          })
          */
      
  
        })
      })
    }

    
  
    return (
      <div>
        <div className="sidebar"><Sidebar /></div>
        <div className="title">
          <h1> Add Event</h1>
        </div>
        <div className="overall">
          <div className="leftHalf">
            <div className="form">
              <PurpleTextField
                id="standard-name"
                label="Event Name"
                onChange={this.handleEventNameChange}
                value={this.state.eventName}
                margin="normal"
                variant="outlined"
                color="primary"
                fullWidth
              />
              <div class="datepicker">
                <h5> Date and Time</h5>
                <form noValidate>
                  <PurpleTextField
                    id="datetime-local"
                    label="Starts"
                    type="datetime-local"
                    defaultValue={this.setDate()}
                    onChange={this.handleStartDateChange}
                    value={this.state.value}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>
                <form noValidate>
                  <PurpleTextField
                    id="datetime-local"
                    label="Ends"
                    type="datetime-local"
                    onChange={this.handleEndDateChange}
                    value={this.state.value}
                    defaultValue={this.setDate()}
                    style={{ marginTop: "10px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>
                <div class="calendarRepeat">
                  <FormControl fullWidth variant="outlined" style={{ marginTop: "10px" }} >
                    <InputLabel id="select-repeat-lable" className="input_label">
                      Repeat
                  </InputLabel>
                    <Select
                      labelId="select-repeat-label"
                      color="primary"
                      value={this.state.repeat}
                      onChange={this.handleRepeatChange}
                    >
                      {repeatFrequency.map((freq) => (
                        <MenuItem key={freq} value={freq}>
                          {freq}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="select-invitees-label" className="input_label">Add Invitee</InputLabel>
                <Select
                  labelId="select-invitees-label"
                  id="select-outlined"
                  value={this.state.invitees}
                  onChange={this.handleInviteesChange}
                  label="Invitees"
                >
                  {this.state.users.map((user) => (
                    <MenuItem key={user.email} value={user.email}>
                      {user.firstName + " " + user.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div class="rightHalf">
            <div class="notes">
              <PurpleTextField
                id="outlined-multiline-static"
                label="Add Notes"
                multiline
                fullWidth
                rows={20}
                variant="outlined"
                value={this.state.notes}
                onChange={this.handleAddNotesChange}
              />
            </div>
          </div>
          {/* button */}
          <div class="submitButton">
            <Button
              variant="contained"
              color="primary"
              // onClick={this.handleSubmit}
              onClick = {handleClick}
            >
              Add Event
              </Button>
          </div>
        </div>
      </div>
    );

  }

}

export default addEvent;
