import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

// actions
import { clearSnackMessage } from '../actions/uiActions'

// thunks
import { requestPasswordChange } from '../thunks';

// selectors
import { 
    selectLoadingUI, 
    selectErrorUI,
 } from '../selectors'

// Styles
//const theme = createMuiTheme()
const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#ffcc99'
      }
    }
  });
var LoginBoxContainer = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1.5%',
    width: '95VW',
    height: '85VH',
}


const styles = {
    mainClass: {
        width: '100%',
        height: '20%',
        color: 'black',
    },
    submit: {
        boxShadow: 'none'

    }
}

export class LoginBox extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        console.log(this.props)
        this.props.clearErrors()
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        }
        this.props.handleRequestPasswordChange(userData, this.props.history);
    }

    render() {

        const { classes, isLoading, errorMessage } = this.props;

        return (
            <>
            
            
            <Container component="main" maxWidth="xs">
                
                <CssBaseline />
                
                
                <div className={classes.paper}>
                    <br></br><br></br>
                    {errorMessage ?
                <SnackbarContent message={errorMessage}></SnackbarContent>
                : null
            }
                    <Typography component="h1" variant="h4">
                        Request Password Change
                    </Typography>
                    <br></br><br></br>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            onChange={this.handleChange}
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            align="left"
                            onClick={this.handleSubmit}
                            className={classes.submit}
                        >
                            <b>Request Password Change</b>
                        </Button>
                        {isLoading ? 
                            <MuiThemeProvider theme={theme}>
                                <LinearProgress color="secondary" />
                            </MuiThemeProvider>
                        : null
                        }              
                    <br></br><br></br>
                </div>
                <Box mt={28}>
                </Box>
                
            </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: selectLoadingUI(state),
    errorMessage: selectErrorUI(state),
})

const mapDispatchToProps = (dispatch) => ({
    handleRequestPasswordChange: (userData, history) => {
        //dispatch(loginUser(userData, history))
        dispatch(requestPasswordChange(userData, history))
    },
    clearErrors: () => {
        dispatch(clearSnackMessage())
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginBox))
