import { SET_USER, SET_MATCHINGS, SET_UNAUTHENTICATED, SET_VALID_NGO, SET_NGO } from '../constants/userConstants';
import axios from 'axios';


export const logoutUser = () => (dispatch) => {
    localStorage.removeItem('FBIdToken');
    localStorage.removeItem('state')
    delete axios.defaults.headers.common['Authorization'];
    dispatch({ type: SET_UNAUTHENTICATED });
};

export const setValidNgo = () => ({
    type: SET_VALID_NGO
})

export const setNgoId = (ngoId) => ({
    type: SET_NGO,
    payload: ngoId,
})

export const setMatchings = (matchings) => ({
    type: SET_MATCHINGS,
    payload: matchings,
})