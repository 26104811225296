import { 
    SET_LOADING_UI, 
    CLEAR_LOADING_UI,
    SET_SNACK_MESSAGE,
    CLEAR_SNACK_MESSAGE
} from '../constants/uiConstants'



const initialState = {
    loadingUI: false,
    errors: null
}

export default function(state= initialState, action) {
    switch(action.type) {
        case SET_SNACK_MESSAGE:
            return {
                ...state, 
                loading: false,
                errors: action.payload
            };
        case CLEAR_SNACK_MESSAGE:
            return {
                ...state,
                loading: false,
                errors: null
            };
        case SET_LOADING_UI:
            return {
                ...state,
                loadingUI: true
            }
        case CLEAR_LOADING_UI:
            return {
                ...state,
                loadingUI: false
            }
        default:
            return state;
    }
}