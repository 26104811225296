// Material-UI Imports
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Color from 'color'


import React, { Component } from 'react';

const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
        value: 5,
        label: '5',
    },
  ];

class PatientForm extends React.Component {
    constructor() {
        super();

        this.state = {
            timeCommit: false, 
            preferredTime: false, 
            providerGender: false, 
            preferredAge: false,
            preferredTimeWeight: 3,
            preferredAgeWeight: 3,
            preferredGenderWeight: 3,
        };
    }

    handleSubmit = () => {
        
        const surveyData = {
            timeCommit: this.state.timeCommit, 
            preferredTime: this.state.preferredTime, 
            providerGender: this.state.providerGender, 
            preferredAge: this.state.preferredAge,
            preferredTimeWeight: this.state.preferredTimeWeight,
            preferredAgeWeight: this.state.preferredAgeWeight,
            preferredGenderWeight: this.state.preferredGenderWeight,
            Biography: "",
        }
        this.props.callBackFromSurvey(surveyData);

    }

    

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleWeightChange = name => (event, value)  => {
        this.setState({
            [name]: value
        });
        console.log(this.state)
    }

    render() {
        return (
            <Container
                style={{
                    font: "Hind",
                }}
                
            >
                <FormControl 
                    fullWidth 
                    variant="outlined"
                    style={{
                        padding: 35,
                        background: "#CCC1F9",
                        borderRadius: 25,
                        marginTop: 10,
                    }}    
                >
                    <FormLabel style={{paddingTop: 10, fontSize: "22px", color: "#212119"}} component="legend">How long will you be working at your NGO?</FormLabel>
                    <RadioGroup style={{paddingTop: 35 , color:"#4D4D4D"}}aria-label="timeCommit" name="timeCommit" onChange={this.handleChange} required>
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Under 3 months" />
                        <FormControlLabel value="2" control={<Radio color="primary" />} label="3-6 months" />
                        <FormControlLabel value="3" control={<Radio color="primary" />} label="6-9 months" />
                        <FormControlLabel value="4" control={<Radio color="primary" />} label="9-12 months" />
                        <FormControlLabel value="5" control={<Radio color="primary" />} label="12-24 months" />
                        <FormControlLabel value="6" control={<Radio color="primary" />} label="2+ years" />
                    </RadioGroup>
                </FormControl>

                <FormControl 
                    fullWidth 
                    variant="outlined"
                    style={{
                        padding: 35,
                        backgroundColor: "#CCC1F9",
                        borderRadius: 25,
                        marginTop: 10,
                    }}    
                >
                    <FormLabel style={{paddingTop: 10, fontSize: "22px", color: "#212119"}} component="legend">What is your preferred time to meet with a provider?</FormLabel>
                    <RadioGroup style={{paddingTop: 35 ,color:"#4D4D4D"}} aria-label="preferredTime" name="preferredTime" onChange={this.handleChange} required>
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="6-9AM" />
                        <FormControlLabel value="2" control={<Radio color="primary" />} label="9-12PM" />
                        <FormControlLabel value="3" control={<Radio color="primary" />} label="12-3PM" />
                        <FormControlLabel value="4" control={<Radio color="primary" />} label="3-6PM" />
                        <FormControlLabel value="5" control={<Radio color="primary" />} label="6-9PM" />
                    </RadioGroup>
                    <FormLabel style={{paddingTop: 10, fontSize: "22px", color: "black"}} component="legend">On a scale of 1-5, how important is this to you?</FormLabel>
                    <Slider
                        name="preferredTimeWeight"
                        onChange={this.handleWeightChange("preferredTimeWeight")}
                        defaultValue={3}
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        marks={marks}
                        min={1}
                        max={5}
                        valueLabelDisplay="auto"
                    />
                </FormControl>

                <FormControl 
                    fullWidth 
                    variant="outlined"
                    style={{
                        padding: 35,
                        background: "#CCC1F9",
                        borderRadius: 25,
                        marginTop: 10,
                    }}    
                >
                    <FormLabel style={{paddingTop: 10, fontSize: "22px", color: "#212119"}} component="legend">Do you have a preference for the sex of your provider?</FormLabel>
                    <RadioGroup style={{paddingTop: 35,color:"#4D4D4D" }} aria-label="providerGender" name="providerGender" onChange={this.handleChange} required>
                        <FormControlLabel value="male" control={<Radio color="primary" />} label="Male provider" />
                        <FormControlLabel value="female" control={<Radio color="primary" />} label="Female provider" />
                    </RadioGroup>
                    <FormLabel style={{paddingTop: 10, fontSize: "22px", color: "#212119"}} component="legend">On a scale of 1-5, how important is this to you?</FormLabel>
                    <Slider
                        defaultValue={3}
                        onChange={this.handleWeightChange("preferredGenderWeight")}
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        marks={marks}
                        min={1}
                        max={5}
                        valueLabelDisplay="auto"
                    />
                </FormControl>

                <FormControl 
                    fullWidth 
                    variant="outlined"
                    style={{
                        padding: 35,
                        backgroundColor: "#CCC1F9",
                        borderRadius: 25,
                        marginTop: 10,
                    }}    
                >
                    <FormLabel style={{paddingTop: 10, fontSize: "22px", color: "#212119"}} component="legend">Do you have a preference for the age of your provider?</FormLabel>
                    <RadioGroup style={{paddingTop: 35,color:"#4D4D4D" }} aria-label="preferredAge" name="preferredAge" onChange={this.handleChange} required>
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="22-30" />
                        <FormControlLabel value="2" control={<Radio color="primary" />} label="30-40" />
                        <FormControlLabel value="3" control={<Radio color="primary" />} label="40-50" />
                        <FormControlLabel value="4" control={<Radio color="primary" />} label="50-60" />
                        <FormControlLabel value="5" control={<Radio color="primary" />} label="60+" />
                    </RadioGroup>
                    <FormLabel style={{paddingTop: 10, fontSize: "22px", color: "#212119"}} component="legend">On a scale of 1-5, how important is this to you?</FormLabel>
                    <Slider
                        defaultValue={3}
                        aria-labelledby="discrete-slider-small-steps"
                        onChange={this.handleWeightChange("preferredAgeWeight")}
                        step={1}
                        marks={marks}
                        min={1}
                        max={5}
                        valueLabelDisplay="auto"
                    />
                </FormControl>
                <FormControl>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={this.handleSubmit} 
                        type="submit" 
                        style={{ direction: "column", alignItems: "center", marginTop: '20%', padding:"8.4%",width:"180px"}}
                        disabled={!this.state.timeCommit || !this.state.preferredTime || !this.state.providerGender || !this.state.preferredAge}
                    >
                        Submit
                    </Button>
                </FormControl>
            </Container>
        )
    }
}

export default PatientForm;