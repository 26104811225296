// Material-UI Imports
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';


import React, { Component, View } from 'react';

function FilterBar(props) {
  
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checkedVolunteers}
            onChange={props.handleFilterChange}
            name="checkedVolunteers"
            color="primary"
          />
        }
        label="Volunteers"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checkedTherapists}
            onChange={props.handleFilterChange}
            name="checkedTherapists"
            color="primary"
          />
        }
        label="Therapists"
      />
    </FormGroup>
   );
}

function UserButton(props) {
  return (
    <Button
      style={{textTransform: "none"}}
      variant="outlined"
      onClick={() => props.handleClick(props.user)}
    >
      <h4>{props.user.firstName + " " + props.user.lastName}</h4>
      &nbsp;&nbsp;&nbsp;
      <div>
      {
        (props.user.userGroup ? props.user.userGroup : "Unknown")
      }
      </div>
    </Button>
  );
}

class UserList extends Component {
    constructor() {
      super();
      this.handleFilterChange = this.handleFilterChange.bind(this);
      this.state = {
        checkedVolunteers: true,
        checkedTherapists: true,
      };
    }

    handleFilterChange(event) {
      this.setState({ ...this.state, [event.target.name]: event.target.checked });
    }

    render() {
        // Sort users in alphabetical order
        function compareUsers(a, b) {
          // Use toUpperCase() to ignore character casing
          const nameA = a.firstName.toUpperCase();
          const nameB = b.firstName.toUpperCase();

          let comparison = 0;
          if (nameA > nameB) {
            comparison = 1;
          } else if (nameA < nameB) {
            comparison = -1;
          }
          return comparison;
        }

        const sortedUsers = this.props.users.sort(compareUsers);
        
        return (
          <div>
            <h1 style={{position: 'relative', top: '20px'}}>Contacts</h1>
            <FilterBar
              checkedVolunteers={this.state.checkedVolunteers}
              checkedTherapists={this.state.checkedTherapists}
              handleFilterChange={this.handleFilterChange}
            />
            <Grid>
                <ul>
                    {
                      sortedUsers.map(
                        (user) => {
                          if(this.state.checkedTherapists && user.userGroup=="Therapist") {
                            return <li key={user.name} style={{paddingBottom:"5px"}}><UserButton user={user} handleClick={this.props.callbackClick} /></li>
                          }
                        }
                      )
                    }
                    {
                      sortedUsers.map(
                        (user) => {
                          if(this.state.checkedVolunteers && user.userGroup=="Volunteer") {
                            return <li key={user.name} style={{paddingBottom:"5px"}}><UserButton user={user} handleClick={this.props.callbackClick} /></li>
                          }
                        }
                      )
                    } 
                </ul>
            </Grid>
          </div>
        );
    }
}
export default UserList;