import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Side Navigator Component Imports
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '../css/react-sidenav.css';

// Bootstrap Icon Imports
import { Chat, Calendar, House, FileEarmark, Telephone, Gear, BoxArrowRight } from 'react-bootstrap-icons';

const Separator = styled.div`
    clear: both;
    position: fixed;
    margin: .8rem 0;
    background-color: #ddd;
    height: 1px;
`;

class Sidebar extends Component {
  state = {
    selected: 'home',
    expanded: false
  };

  onSelect = (selected) => {
    this.setState({ selected: selected });
  };
  onToggle = (expanded) => {
    this.setState({ expanded: expanded });
  };

  constructor() {
    super();
  }

  render() {
    return <SideNav style={{ borderRight: '1px solid gray' }}
      onSelect={(selected) => {
        // Add your code here
      }}
    >
      <SideNav.Toggle />
      <SideNav.Nav defaultSelected="home" >

        <NavItem eventKey="home">
          <NavIcon>
            <Link to="/home"><House /> </Link>
          </NavIcon>
          <NavText title="Home">
            <Link to="/home"> Home </Link>
          </NavText>
        </NavItem>

        <NavItem eventKey="forums">
          <NavIcon>
            {/* TODO:: MESSAGES */}
            <Link to="/chatpage"><Chat /></Link>
          </NavIcon>
          <NavText title="FORUMS">
            <Link to="/chatpage">Messages</Link>
          </NavText>
        </NavItem>

        <NavItem eventKey="calendar">
          <NavIcon>
            <Link to="/Calendar"><Calendar /> </Link>
          </NavIcon>
          <NavText title="CALENDAR">
            {/* TODO:: add link */}
            <Link to="/Calendar"> Calendar </Link>
          </NavText>
        </NavItem>

        <NavItem eventKey="content">
          <NavIcon>
            <Link to="/contentFeed"><FileEarmark /></Link>
          </NavIcon>
          <NavText title="CONTENT">
            <Link to="/contentFeed">Content</Link>
          </NavText>
        </NavItem>

        <NavItem eventKey="video_call">
          <NavIcon>
            <Link to="/Calendar"><Telephone /></Link>
          </NavIcon>
          <NavText title="VIDEO CALL">
            {/* TODO:: add link to video call */}
            <Link to="/Calendar">Video call</Link>
          </NavText>
        </NavItem>

        <NavItem eventKey="settings">
          <NavIcon>
            <Link to="/home"><Gear /></Link>
          </NavIcon>
          <NavText title="SETTINGS">
            {/* TODO:: add link to settings */}
            <Link to="/home">Settings</Link>
          </NavText>
        </NavItem>

        <NavItem eventKey="log_out">
          <NavIcon>
            <Link to="/home"><BoxArrowRight /></Link>
          </NavIcon>
          <NavText title="LOG_OUT">
            {/* TODO:: log out */}
            <Link to="/home">Log out</Link>
          </NavText>
        </NavItem>

      </SideNav.Nav>

    </SideNav>
  }
}

export default Sidebar