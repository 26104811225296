import { createSelector } from 'reselect'

const getUI = state => state.UI;
const getUser = state => state.user;

export const selectLoadingUI = createSelector(
    getUI,
    (UI) => UI.loadingUI
)

export const selectErrorUI = createSelector(
    getUI,
    (UI) => UI.errors
)

export const selectNgoId = createSelector(
    getUser,
    (user) => user.ngoId
)

export const selectValidNgoId = createSelector(
    getUser,
    (user) => user.ngoIdValid
)

export const selectUserCredentials = createSelector(
    getUser,
    (user) => user.credentials
)

export const selectUserMatchings = createSelector(
    getUser,
    (user) => user.matchings
)