// Material-UI Imports
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Navbar from '../components/Navbar';
import SiteMap from '../components/SiteMap'

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PatientForm from '../components/PatientForm';
import ProviderForm from '../components/ProviderForm';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { CardHeader } from '@material-ui/core';
import React, { Component } from 'react';

// Redux stuff
import store from '../store';
import { connect } from 'react-redux'
import { submitSurvey } from '../thunks'
import axios from 'axios';
import { clearSnackMessage } from '../actions/uiActions'
// selectors
import { 
    selectLoadingUI, 
    selectErrorUI,
    selectUserCredentials,
 } from '../selectors'


// const styles = {
//     container: {
//         height: '100%',
//     },
// };
const styles = theme => ({
    container: {
        height: '100%',
    },
    root:{
		display: 'flex',
        alignItems: 'center',
        margin:'7.5%'
	},
    title: {
        color: 'black',
        fontWeight: 550,
        fontFamily: "Hind",
        
    },
    subheader: {
        color: 'black',
        fontFamily: "Hind",
    },
    input: {
        color: "white",
        backgroundColor: "#8c8cf5",
        fontFamily: "Hind",
    }
  });
const THEME = createMuiTheme({
    typography: {
      "fontFamily": "\"MyCustomFont\"",
      "letterSpacing": 0.32,
      "fontFamily": "Hind",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
      h1: {
        "fontWeight": 600,
        color:"black"
      },
      h2: {
        "fontWeight": 600,
        "fontFamily": "Hind",
      },
      h5: {
          color: "black"
      }
    },
});
class Survey extends Component {

    constructor() {
        super();
        this.state = {
            
        }
    }
    componentDidMount() {
        console.log('something')
        console.log(this.props.userCredentials)
    }

    handleSurvey = (surveyData) => {
        const userHandle = this.props.userCredentials.handle;
        const userGroup = this.props.userCredentials.userGroup;
        surveyData.handle = userHandle;
        this.props.handleSubmitSurvey(surveyData, userGroup, this.props.history);
    }

    render() {
        const { classes, userCredentials } = this.props;
        const background ={
            backgroundColor: "#F7F7FF",
        }
        const padding={
            paddingBottom:'3.2%',
            position:'relative',
            right:'1.0%'
        }

        return (
            <div style={background}>
                <Navbar />
                <MuiThemeProvider theme={THEME}>
                <FormControl fullWidth variant="outlined" style={{ paddingRight:'8.4%', paddingLeft:'8.4%'}}>
                <CardHeader
                    class={{
						root:classes.root
					}}
					style={{ paddingRight:'8.4%', paddingLeft:'8.4%'}}
                    title={"Welcome To Huddl!"}
                    subheader={ userCredentials.userGroup === "Volunteer" ?
                    "Before we get started, we need you to take a survey to match you with a compatible provider. Please answer all the questions below."
                    :
                    "Before we get started, we need you to take a survey to match you with a compatible patient. Please answer all the questions below."
                    }

                    style={{
                        // textAlign: "left",
                        marginTop: 25,
                        // marginLeft: 15,
                        fontFamily: "Hind",
                        color: 'black'
                    }}
                    subtitleStyle={{
                        marginTop: 25,
                        color: 'black',
                        paddingLeft:'8.4%'
                    }}
                    titleTypographyProps={{variant:'h2' }}
                    subheaderTypographyProps={{variant:'h6'}}
                >
                </CardHeader>
                </FormControl>
                </MuiThemeProvider>
                <div style={padding} >
                {userCredentials.userGroup === "Volunteer" ?
                <PatientForm callBackFromSurvey={this.handleSurvey} 
                /> :
                <ProviderForm callBackFromSurvey={this.handleSurvey} />
                }
                </div>
                <div style={padding}></div>
                <SiteMap />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: selectLoadingUI(state),
    errorMessage: selectErrorUI(state),
    userCredentials: selectUserCredentials(state),
})

const mapDispatchToProps = (dispatch) => ({
    clearErrors: () => {
        dispatch(clearSnackMessage())
    },
    handleSubmitSurvey: (surveyData, userGroup, history) => {
        dispatch(submitSurvey(surveyData, userGroup, null, history))
    }
})



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Survey))
