import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import SiteMap from '../components/SiteMap'
import ForgotPasswordContainer from '../components/ForgotPasswordContainer'
import axios from 'axios'
import { Link } from 'react-router-dom';

// redux
import { connect } from 'react-redux';


axios.defaults.baseURL = 'https://us-central1-letshuddl.cloudfunctions.net/api';

// Style definitions
var container = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'

}

class login extends Component {


    componentDidMount() {
        console.log(this.props)
    }
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            errors: {
              "general": "",
              "email": "",
              "password": ""
            }
        }
    }

    render() {
        return (
            <div style={container}>
                <Navbar />
                <ForgotPasswordContainer />
                <SiteMap />
            </div>
        )
    }

}

const mapStateToProps = (state) => ({

});

const mapActionsToProps = {
    
}

export default connect(mapStateToProps, mapActionsToProps)(login);