import { 
    SET_USER, 
    SET_SNACK_MESSAGE, 
    CLEAR_SNACK_MESSAGE, 
    CLEAR_LOADING_UI, 
    SET_LOADING_UI, 
    SET_UNAUTHENTICATED 
} from '../constants/uiConstants';

export const setLoadingUI = () => ({
    type: SET_LOADING_UI
})

export const clearLoadingUI = () => ({
    type: CLEAR_LOADING_UI
})

export const setSnackMessage = (message) => ({
    type: SET_SNACK_MESSAGE,
    payload: message,
})

export const clearSnackMessage = () => ({
    type: CLEAR_SNACK_MESSAGE,
})