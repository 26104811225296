import { SET_SNACK_MESSAGE, CLEAR_SNACK_MESSAGE } from '../constants/uiConstants';
import { SET_USER, SET_UNAUTHENTICATED } from '../constants/userConstants';
import { setLoadingUI, clearLoadingUI, setSnackMessage, clearSnackMessage } from '../actions/uiActions'
import { setValidNgo, setNgoId, setMatchings  } from '../actions/userActions'
import axios from 'axios';

axios.defaults.baseURL = 'https://us-central1-letshuddl.cloudfunctions.net/api';

export const loginUser = (userData, history) => (dispatch) => {

    dispatch(setLoadingUI())
    axios.post('/login', userData).then(data => {
        localStorage.setItem('FBIdToken', `Bearer ${data.data}`);
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.data}`;
        dispatch(getUserData(history));
        dispatch(clearSnackMessage());
    }).catch(err => {
        dispatch(setSnackMessage(err.response.data.errors['general']))
    }).finally(() => {
        dispatch(clearLoadingUI())
    })
};


    // 1. Call '/submitSurvey' endpoint to update this users survey object with the options selected.
    // 2. On 200 response, call '/contacts' endpoint to get a list of of matching handles and numbers representing the level of matching
    // 3. On 200 response, call '/getUser/ endpoint with each matched therapist handle to get user details for each therapist
    // 4. On 200 response, begin steps to dispatch data to redux store
export const submitSurvey = (surveyData, userGroup, userID, history) => (dispatch) => {
    console.log('submitting survey');
    /*
    console.log(surveyData)
    console.log(userGroup)
    let matchings = []
    if (userGroup == "Volunteer") {
        axios.post('/sendSurvey', surveyData).then(() => {
            axios.post('/contacts', surveyData).then((data) => {
                // get matching data
                
                data.forEach((matchedTherapist)=> {
                    // modify this endpoint so it can take a therapistID as an argument
                    // instead of just the logged-in users ID
                    // or create a new endpoint that can take the list of therapists itself
                    // and return a list of therapist credentials
                    axios.post('/getUser', matchedTherapist).then((matchedCredentials) => {
                        matchings.push(matchedCredentials);
                    })
                })
            }).then(() => {
                dispatch(setMatchings(matchings))
                history.push("/matchingpage")
            }).catch((err)=> { console.log(err)})
        }).catch(err=> {
            console.log(err);
        }).finally(() => { })
    }
    else {
        // this user is just a therapist so push the survey and redirect to home
        axios.post('/sendSurvey', surveyData).then(() => { 
            history.push('/home')
        }).catch((err)=> { console.log(err)})
    }
    */
   axios.post('/sendSurvey', surveyData).then(() => { 
        history.push('/home')
    }).catch((err)=> { console.log(err)})
    history.push('/home')
}


export const signupUser = (newUserData, history) => (dispatch) => {
    console.log('requesting new user signup...')
    dispatch(setLoadingUI())
    axios.post('/signup', newUserData).then( data => {
        history.push('/login');
        dispatch(setSnackMessage("Thank you for signing up. Please check your inbox for a verification email."))
    }).catch(err => {
        if (err && err.response)
            dispatch(setSnackMessage(Object.values(err.response.data.errors)))

    }).finally(() => {
        dispatch(clearLoadingUI())
    })
};


export const getUserData = (history) => (dispatch) => {
    axios.get('/user').then(res => {
        dispatch({
            type: SET_USER,
            payload: res.data
        });
        if (!res.data.credentials.surveyData) {
            history.push('/survey')
        } else {
            history.push('/home')
        }
    }).catch(err => {
        console.log('Error in getting user data');
    });
};



export const requestPasswordChange = (userData, history) => dispatch => {
    dispatch(setLoadingUI())
    axios.post('/forgotPassword', userData).then(data => {
        dispatch(setSnackMessage("Please check your inbox for a password reset link."))
    }).catch(() => {
        dispatch(setSnackMessage("Failed to request password reset.\n Please check the address and try again."))
    }).finally(() => {
        dispatch(clearLoadingUI())
    })
}

export const checkEmailVerified = (userData) => dispatch => {
    console.log('checking if user\'s email is verfied')
    axios.post('/checkEmailVerified', userData).then(data => {
        
    })
}

export const validateNgoId = (ngoId) => dispatch => {
    console.log("inside thunk verifiyng ngo id")
    //console.log(ngoId)
    // would really make a call to DB and check if ngo is valid
    // and if true
    //     dispatch ngoid to set in store and ngovalid=true to store
    //     else dispatch an error message action to reducer
    // for now, just always dispatching true
    dispatch(setNgoId(ngoId))
    dispatch(setValidNgo())
}

