import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider as MuiThemeProvider, createMuiTheme, withTheme } from '@material-ui/core/styles';
import { CardHeader } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import { connect } from 'react-redux';
import Color from 'color'
// thunks
import { validateNgoId } from '../thunks'

// selectors
import { 
    selectLoadingUI, 
    selectErrorUI,
    selectNgoId,
 } from '../selectors'


// Styles

const THEME = createMuiTheme({
    typography: {
      "fontFamily": "Hind",
      "letterSpacing": 0,
      useNextVariants: true,
      suppressDeprecationWarnings: true,
      h3: {
        "fontWeight": 600,
        fontFamily: "Hind",
        color: '#fafcfe',
      },
      h3: {
        color: '#fafcfe',
        fontFamily: "Hind",
      },
      h4: {
         "color": "white",
         fontFamily: "Hind",
      },
    },
});

const styles = theme => ({
    title: {
        color: '#fafcfe',
        fontWeight: 550,
        fontFamily: "Hind",
    },
    subheader: {
        color: '#fafcfe',
        fontFamily: "Hind",
    },
    input: {
        color: "white",
        backgroundColor: "#8c8cf5",
        fontFamily: "Hind",
    }
  });
  

class NgoModal extends Component {

    constructor() {
        super();
        this.state = {
            ngoId: false,
        }
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        const { classes } = this.props;

      return (
        <Modal
            disablePortal
            disableEnforceFocus
            autoFocus
            open
            center
            style={{
                backgroundColor: '#7C7CFA',
                zIndex: 100,
                margin: '5.0rem auto',
                borderRadius: '25px',
                borderWidth: 0,
                minWidth: '400px',
                maxWidth: '600px',
                padding: '2rem',
            }}
            >
            <>
            <FormControl fullWidth variant="outlined">
            <MuiThemeProvider theme={THEME}>
                
                    <CardHeader
                        classes={{
                            title: classes.title,
                            subheader: classes.subheader
                          }}
                        title="Welcome to Huddl!"
                        titleTypographyProps={{variant:'h3' }}
                    >
                    </CardHeader>
                    <CardHeader
                        classes={{
                            title: classes.title,
                        }}
                        title="We are only letting volunteers from approved NGOs sign up at this time."
                        titleTypographyProps={{variant:'h5' }}
                    >
                    </CardHeader>
                    <CardHeader
                        classes={{
                            subheader: classes.subheader,
                          }}
                        subheader="If you qualify, please enter the password provided by your NGO to make an account.
                          We're excited to have you on board!"
                        titleTypographyProps={{variant:'p' }}
                    >
                    </CardHeader>
                    <CardHeader
                        classes={{
                            title: classes.title,
                            subheader: classes.subheader
                          }}
                        title="NGO ID"
                        titleTypographyProps={{variant:'h4' }}
                    >
                    </CardHeader>
            </MuiThemeProvider>
            </FormControl>
            <FormControl fullWidth variant="outlined">
                
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    autoFocus
                    style={{
                        minWidth: 200, 
                        maxWidth: 400,
                        marginLeft: 15,
                    }}
                    id="ngoId"
                    name="ngoId"
                    autoFocus
                    onChange={this.handleChange}
                    InputProps={{
                        className: classes.input,
                    }}
                />
                </FormControl>
                <Button
                        type="submit"
                        style={{
                            borderRadius: 15,
                            backgroundColor: "#fafcfe",
                            padding: "18px 36px",
                            fontSize: "18px",
                            minWidth: 200,
                            maxHeight: 25,
                            margin: 15,

                        }}
                        variant="contained"
                        color="secondary"
                        disabled={!this.state.ngoId}
                        onClick={() => {this.props.handleSubmit(this.state.ngoId)}}
                        className={classes.submit}
                    >
                        <b>Submit</b>
                    </Button>
                    </>
        </Modal>
        
          )
    }

}


const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
    ngoId: selectNgoId(state),
    isLoading: selectLoadingUI(state),
    errorMessage: selectErrorUI(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleSubmit: (ngoId) => {
        dispatch(validateNgoId(ngoId))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NgoModal))