import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';


import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

// actions
import { clearSnackMessage } from '../actions/uiActions'

// thunks
import { loginUser } from '../thunks';

// selectors
import { 
    selectLoadingUI, 
    selectErrorUI,
 } from '../selectors'

// Styles
const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#ffcc99'
      },
      secondaryText: {
        main: "blue"
      }
    }
  });
const styles = {
    root:{
		width: '100%',
    boxSizing: 'border-box',
    marginLeft: 'auto',
	marginRight: 'auto',
	paddingLeft:'8.4%',
	paddingRight:'8.4%',
	},
    mainClass: {
        width: '100%',
        height: '20%',
        color: 'black',
        fontFamily: "Hind",
    },
    submit: {
        boxShadow: 'none',
        fontFamily: "Hind",
    },
    notchedOutline: {
        borderWidth: "2px",
        borderColor: "#6e6efa !important",
        fontFamily: "Hind",
    },
}
export class LoginBox extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            password: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.props.clearErrors()
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        }
        this.props.handleLogin(userData, this.props.history);
    }

    render() {

        const { classes, isLoading, errorMessage } = this.props;

        return (
            <>
            <Container 
            class={{
                root:classes.root
            }}
            style={{ paddingRight:'8.4%', paddingLeft:'8.4%'}}
                component="main" 
                    styles={{
                        fonFamily: "Hind",
                    }}>
                {errorMessage ?
                    <SnackbarContent 
                        message={errorMessage}
                        style={{minWidth: 300, maxWidth: 350}}
                    >    
                    </SnackbarContent>
                    : null
                }
                        <FormControl fullWidth variant="outlined">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            className={classes.textField}
                            style={{minWidth: 400, maxWidth: 500, }}
                            id="email"
                            onChange={this.handleChange}
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            InputProps={{
                                classes: {
                                  root: classes.cssOutlinedInput,
                                  focused: classes.cssFocused,
                                  notchedOutline: classes.notchedOutline,
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: "#6e6efa",
                                }
                            }}
                        />
                        </FormControl>
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            style={{minWidth: 400, maxWidth: 500}}
                            onChange={this.handleChange}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                classes: {
                                  root: classes.cssOutlinedInput,
                                  focused: classes.cssFocused,
                                  notchedOutline: classes.notchedOutline,
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: "#6e6efa",
                                }
                            }}
                        />
                        </FormControl>
                        
                        <FormControl fullWidth style={{marginTop: 10, marginBottom: 10}} className={classes.margin} variant="outlined">
                            <Typography style={{display: 'inline-block'}}>  {"I "}
                                <Link color="secondary" underline="always" href="/forgotpassword" style={{display: 'inline-block'}}>
                                    forgot my password
                                </Link>
                            </Typography>     
                        </FormControl>     
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <Typography style={{display: 'inline-block'}}>  {"I need to "}
                                    <Link color="secondary" underline="always" href="/signup" style={{display: 'inline-block'}}>
                                        create an account
                                    </Link>
                                </Typography> 
                        </FormControl>
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <Button
                                type="submit"
                                style={{
                                    width: 150,
                                    marginTop: 10,
                                }}
                                variant="contained"
                                color="primary"
                                disabled={this.state.email==='' || this.state.password===''}
                                onClick={this.handleSubmit}
                                className={classes.submit}
                            >
                                <b>Log in</b>
                            </Button>
                            {isLoading ?
                                <MuiThemeProvider theme={theme}>
                                    <LinearProgress 
                                        color="secondary"
                                        style={{
                                            width: 150,
                                        }} 
                                    />
                                </MuiThemeProvider>
                            : null
                            }      
                        </FormControl>
                <Box mt={28}>
                </Box>
            </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: selectLoadingUI(state),
    errorMessage: selectErrorUI(state),
})

const mapDispatchToProps = (dispatch) => ({
    handleLogin: (userData, history) => {
        dispatch(loginUser(userData, history))
    },
    clearErrors: () => {
        dispatch(clearSnackMessage())
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginBox))
