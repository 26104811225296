import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import SiteMap from '../components/SiteMap'
import LoginBox from '../components/LoginBox'
import axios from 'axios'
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { CardHeader } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/styles/withStyles';



// redux
import { connect } from 'react-redux';
import { loginUser } from '../thunks';


// Style definitions
var container = {
    display: 'flex',
    flexDirection: 'column',
}
const styles = theme => ({
    root:{
		display: 'flex',
    /* padding: 16px; */
    alignItems: 'center',
    },
    title: {
        color: 'black',
        fontWeight: 550,
        fontFamily: "Hind",
        
    },
    subheader: {
        color: 'black',
        fontFamily: "Hind",
    },
    
});
const THEME = createMuiTheme({
    typography: {
      "fontFamily": "\"MyCustomFont\"",
      "letterSpacing": 0.32,
      "fontFamily": "Hind",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
      h2: {
        "fontWeight": 600,
        "fontFamily": "Hind",
      },
    },
});

class login extends Component {


    componentDidMount() {
        console.log(this.props)
    }
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            errors: {
              "general": "",
              "email": "",
              "password": ""
            }
        }
    }

    handleLogin = (userData) => {

        this.setState({
            errors: {
              "general": "",
              "email": "",
              "password": ""
            }
        });
    }

    render() {
        const { classes } = this.props;
        const background ={
            backgroundColor: "#F7F7FF",
        }
        return (
            <div style={background}>
                <Navbar />
                <MuiThemeProvider theme={THEME}>
                <FormControl fullWidth variant="outlined" style={{ paddingRight:'8.4%', paddingLeft:'8.4%'}}>
                <CardHeader
                    class={{
						root:classes.root
					}}

                    title={"Log in"}
                    subheader={"Enter your email and password below."}
                    style={{
                        marginTop: 25,
                        // marginLeft: 15,
                        fontFamily: "Hind",
                        color: 'black'
                    }}
                    subtitleStyle={{
                        marginTop: 25,
                        marginLeft: 15,
                        color: 'black'
                    }}
                    titleTypographyProps={{variant:'h2' }}
                    subheaderTypographyProps={{variant:'h6'}}
                >
                </CardHeader>
                </FormControl>
                </MuiThemeProvider>
                <LoginBox history={this.props.history}/>
                <SiteMap />
            </div>
        )
    }

}

const mapStateToProps = (state) => ({

});

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(login));